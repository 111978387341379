.rounded-box {
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 220px;
  margin: 5px;
}

.title {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.subtitle-container {
  display: flex; /* Use flexbox to align items horizontally */
  justify-content: space-between; /* Space between subtitle and delete icon */
  align-items: center; /* Center vertically */
}

.subtitle {
  font-size: 0.7em;
  color: #777;
}

.delete-icon {
  color: grey; /* Change the color to your preferred delete icon color */
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
  border: none;
  background-color: inherit;
}

.delete-icon:hover {
  color: red;
}

@media (min-width: 768px) {
  .rounded-box {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 220px;
    margin: 5px;
  }

  .title {
    font-size: 1em;
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .subtitle {
    font-size: 0.7em;
  }
}
