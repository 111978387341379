.message-input {
  display: flex;
  align-items: center; /* Center vertically */
  width: 100%; /* Take the full width of the container */
  margin-bottom: 20px;
}

.clear-button {
  background: none; /* Remove background color */
  border: none; /* Remove border */
  font-size: 1.2em; /* Adjust font size as needed */
  cursor: pointer;
  outline: none; /* Remove outline on focus (for better accessibility) */
  margin-right: 10px; /* Add margin between the buttons */
  padding: 5px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

.clear-button:hover {
  box-shadow: 0 0 15px #00a2ff; /* Blue glow */
  transform: scale(1.05); /* Optional: Slightly increase the button size */
}

/* Pressed effect */
.clear-button:active {
  transform: scale(1); /* Return to normal size */
  box-shadow: 0 0 10px #007bff; /* Optional: Change to a lesser glow */
}

.text-area {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-private {
  font-size: 0.6em;
  margin-bottom: 4px;
  padding-left: 4px;
  color: green;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hints-button {
  background: none; /* Remove background color */
  border: none; /* Remove border */
  cursor: pointer;
  outline: none; /* Remove outline on focus (for better accessibility) */
  margin-right: 10px; /* Add margin between the buttons */
  border-radius: 5px;
  padding: 3px 10px;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
  color: grey;
  font-weight: 800;
}

.hints-button:hover {
  box-shadow: 0 0 15px #00a2ff; /* Blue glow */
  transform: scale(1.05); /* Optional: Slightly increase the button size */
}

/* Pressed effect */
.hints-button:active {
  transform: scale(1); /* Return to normal size */
  box-shadow: 0 0 10px #007bff; /* Optional: Change to a lesser glow */
}

.input-text {
  flex: 1; /* Take up remaining space */
  padding: 8px; /* Adjust padding as needed */
  border: none; /* Add borders or other styles as needed */
  border-radius: 10px;
  font-size: 0.9em;
  text-size-adjust: 100%;
}

.send-button {
  background-color: inherit; /* Button background color */
  color: #fff; /* Text color */
  padding: 8px 10px; /* Adjust padding as needed */
  border: none;
  border-radius: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.2em;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

.send-button:hover {
  box-shadow: 0 0 15px #00a2ff; /* Blue glow */
  transform: scale(1.05); /* Optional: Slightly increase the button size */
}

/* Pressed effect */
.send-button:active {
  transform: scale(1); /* Return to normal size */
  box-shadow: 0 0 10px #007bff; /* Optional: Change to a lesser glow */
}

.send-button:disabled {
  background-color: #ccc; /* Button background color when disabled */
  cursor: not-allowed; /* Change cursor to not-allowed */
}

.input-form button:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .send-button {
    padding: 8px 10px; /* Adjust padding as needed */
    margin-left: 5px;
    font-size: 1.5em;
  }
  .clear-button {
    font-size: 1.5em; /* Adjust font size as needed */
    cursor: pointer;
    outline: none; /* Remove outline on focus (for better accessibility) */
    margin-right: 10px; /* Add margin between the buttons */
  }
  .input-text {
    font-size: 1.1em;
    padding: 12px;
  }
  .text-private {
    font-size: 0.8em;
    font-weight: 600;
  }
}
