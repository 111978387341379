.message-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative; /* Make .message-container the positioning context for the icon */
}

.message {
  padding: 8px 15px;
  margin: 5px 10px;
  border-radius: 10px;
  max-width: 90%;
  white-space: pre-wrap;
  line-height: 1.5;
  font-size: 0.8em;
  /*border: 1px solid #dcdcdc; /* light grey color */
  position: relative;
}

.message.user {
  background-color: #99cde4;
}

.message-container.user {
  justify-content: flex-end;
}

.message.assistant {
  background-color: #f5f5f5;
  padding-bottom: 25px;
}

.message.warning {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 3px;
  font-size: 0.8em;
  color: green;
  border: 0px;
}

.word {
  opacity: 0;
  transform: translateY(-30%); /* start from the top */
  display: inline-block;
  transition: opacity 0.5s ease, transform 0.5s ease;
  animation: fadeInMoveDown 0.5s forwards;
  margin-right: 4px; /* Space between words */
}

@keyframes fadeInMoveDown {
  to {
    opacity: 1;
    transform: translateY(0); /* end at the natural position */
  }
}

@keyframes ellipsis {
  0%,
  100% {
    content: ".";
  }
  20% {
    content: "..";
  }
  40% {
    content: "...";
  }
  60% {
    content: "....";
  }
  80% {
    content: ".....";
  }
}

.loading-indicator::after {
  content: ".";
  display: inline-block;
  animation: ellipsis 1s infinite;
}

.code-snippet {
  background-color: #f0f0f0;
  padding: 10px;
  margin-top: 10px;
}

.code-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.copy-icon {
  position: absolute;
  bottom: 5px;
  right: 8px;
  cursor: pointer;
  user-select: none; /* Prevents the icon from being selected */
  opacity: 0.3; /* Start with the icon hidden */
  transition: opacity 0.5s ease; /* Add a transition for the opacity */
  color: #99cde4;
}

/* Optional: Add hover effect for better UX */
.copy-icon:hover {
  opacity: 0.7;
}
/* When hovering over the entire container, show the icon */
.message-container:hover .copy-icon {
  opacity: 1;
}

@media (min-width: 768px) {
  .message {
    font-size: 1em;
  }
}
