.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.company-logo {
  max-width: 200px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.login-link {
  color: #007bff;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}

.bounce-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.bounce-loader > div {
  width: 15px;
  height: 15px;
  background-color: #000;
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
  margin: 0 5px; /* Adding margin for spacing between dots */
}

.bounce-loader .bounce1 {
  animation-delay: -0.32s;
}

.bounce-loader .bounce2 {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@media (min-width: 768px) {
  .company-logo {
    max-width: 300px;
  }
}
