.app-header {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items horizontally */
  justify-content: space-between; /* Center items vertically */
  background-color: inherit;
  padding: 10px 0;
}

.app-logo {
  padding: 12px 45px;
  height: 1em; /* Adjust as necessary */
  margin-bottom: 0px;
  width: auto;
  align-self: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes logoBounce {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-1px) translateX(1px);
  }
  50% {
    transform: translateY(0) translateX(2px);
  }
  75% {
    transform: translateY(1px) translateX(1px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.logo-bounce img {
  animation: logoBounce 2s infinite;
}

.selectedTopicDisplay {
  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
  font-size: 1em;
  /* text-align: right; */
  color: rgba(233, 240, 241, 0.709);
  padding-right: 40px;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@media (min-width: 768px) {
  .app-logo {
    padding: 10px;
    height: 1.8em; /* Adjust as necessary */
    width: auto;
  }
  .selectedTopicDisplay {
    font-size: 1.4em;
    padding-right: 20px;
  }
}
