.chatWrapper {
  flex: 1; /* Ensure it takes up all available vertical space */
  display: flex;
  height: fit-content;
  flex-direction: row;
}

.chatContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 3px; /* Move padding from chatbox to chatContainer */
}

.chatbox {
  flex: 1;
  height: auto;
  max-height: calc(100vh - 150px - 100px);
  /* max-width: 90%; */
  overflow-y: auto;
  padding: 10px; /* Reduce the padding if necessary */
  border-radius: 10px;
  /* background-color: aliceblue; */
}

.chatbox-mobile {
  flex: 1;
  height: auto;
  max-height: calc(100vh - 150px - 80px);
  /* max-width: 90%; */
  overflow-y: auto;
  padding: 10px; /* Reduce the padding if necessary */
  border-radius: 10px;
  /* border: 1px solid #ddd; */
  /* background-color: aliceblue; */
}

.spacer {
  height: 3em;
}

.introduction {
  height: 80%;
  display: flex;
  /* max-width: 500px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 50px;
  text-align: center;
  color: white;
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.selectATopic {
  font-size: 1em;
  font-weight: bold;
}

.title-logo {
  padding: 12px 45px;
  height: 2.2em; /* Adjust as necessary */
  margin-bottom: 0px;
  width: auto;
  align-self: center;
  opacity: 1;
  transition: opacity 2s linear;
}

.title-logo.visible {
  opacity: 1;
}

@media (min-width: 768px) {
  .selectATopic {
    font-size: 1.1em;
    font-weight: bold;
  }
  .introduction {
    font-size: 1.4em;
  }
  .title-logo {
    height: 2.5em; /* Adjust as necessary */
  }
}
