.drawer-item {
  background-color: #f9f9f9c3;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.drawer-item.selected {
  border-color: #ffffff; /* White border for selected state */
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7); /* Enhanced bright glow effect for the selected item */
}

.drawer-item:hover {
  background-color: #e6e6e6;
}

.item-title {
  font-size: 0.8em;
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: 600;
  color: black;
}

.item-description {
  font-size: 0.7em;
  color: #7a7a7a;
}

@media (min-width: 768px) {
  .item-title {
    font-size: 1em;
    margin-top: 8px;
    margin-bottom: 3px;
    font-weight: 600;
    color: black;
  }

  .item-description {
    font-size: 0.7em;
    color: #7a7a7a;
  }
}
