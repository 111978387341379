ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
}

li {
  padding: 10px 0; /* Add vertical padding */
  cursor: pointer; /* Change cursor to indicate clickable items */
  transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

li:hover {
  font-size: 110%;
}

li.selected {
  font-weight: bold;
  color: #7486e5;
}

.historyDrawer {
  position: fixed; /* Changed from static positioning to fixed */
  top: 0;
  right: -250px;
  height: 100%;
  width: 250px;
  background-color: #f5f5f5;
  overflow-y: auto;
  padding: 20px;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Transition effect for the right property */
  transform: translateX(100%);
  z-index: 1000; /* Ensure it's above other elements */
  border-left: 1px solid #ddd;
}

.historyDrawer.drawerOpen {
  transform: translateX(
    -250px
  ); /* Assuming the drawer width is 300px, we move the icon 270px to the left */
  padding-top: 50px;
}

.historyIcon {
  position: fixed; /* This will keep the button positioned relative to the viewport */
  top: 12px;
  right: 10px;
  padding: 5px;
  margin-bottom: 15px;
  transition: transform 0.3s ease; /* Smooth transition for movement */
  background: none;
  border: 1px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  z-index: 1001; /* Ensure the icon is above other elements */
}

.historyIcon.iconMoved {
  transform: translateX(
    -240px
  ); /* Slide the button out when the left drawer is open */
}

.historyIcon:hover {
  opacity: 0.7;
}

.searchTopicsDrawer {
  position: fixed;
  top: 0;
  left: -300px; /* Hide the drawer by default */
  height: 100%;
  width: 250px;
  background-color: #333;
  color: white;
  padding: 20px;
  transition: transform 0.3s ease; /* Transition effect for the right property */
  transform: translateX(-100%);
  z-index: 1000; /* Ensure it's above other elements */
}

.searchTopicsDrawer.drawerOpen {
  transform: translateX(300px); /* Slide the drawer out when it's open */
}

.searchTopicsIcon {
  position: fixed;
  top: 14px;
  left: 10px;
  padding: 5px;
  transition: transform 0.3s ease;
  background: none;
  border: none;
  font-size: 0.7em;
  cursor: pointer;
  z-index: 1001; /* Ensure the icon is above other elements */
}

.searchTopicsIcon i {
  font-size: 1.8em; /* Adjust size as needed */
  color: grey; /* Adjust color as needed */
}

.searchTopicsIcon.drawerOpen {
  transform: translateX(
    230px
  ); /* Slide the button out when the left drawer is open */
}

.App {
  height: 100vh;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /*position: relative; /* This is necessary to position child elements absolutely */
}

.mobile {
  height: 95vh;
}

@media (min-width: 768px) {
  .App {
    width: 90%; /* Width for wider screens */
    max-width: none; /* Remove the max-width restriction */
  }
  .historyIcon {
    font-size: 2em;
    top: 15px;
  }
  .searchTopicsIcon {
    top: 18px;
  }
  .searchTopicsIcon i {
    font-size: 2em;
  }
}

/* ... existing styles ... */
