/* src/App.css */

html,
body {
  overflow-y: hidden; /* Disable vertical scrolling for the entire page */
  font-family: "Roboto", sans-serif;
  background: linear-gradient(
    -6deg,
    #308ed2 0%,
    #e4e3ce 8%,
    #d69488 10%,
    #dbac8f 12%,
    #dfd999 15%,
    #3f99ca 50%
  );
}
